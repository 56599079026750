/* global trackingPage ga farkDebug fark:true*/

// From js/jqueryFunctionsMobile.js
// The SHARE box that pops up on each comment


$( document ).on("click", "a[data-more=yup]",  function() {
    $(".closeShareComment:visible").trigger("click");
    var thisTop = ($(this).next().hasClass('commentsShare')) ? "-150px" : "-175px";
    $(this)
        .next()
        .css({ top: "-80px", opacity: 0, display: "block"})
        .animate( {
            top: thisTop,
            opacity: 1
        }, 150, "swing");
});

$(document).on("click", ".closeShareComment", function() {
    $popup = $(this).parent();
    $popup.animate( {
        top: "-80px",
        opacity: 0
    }, 150, "swing", function() {
        $popup.css("display", "none");
    } );
});

$( "a[href^=mailto]" ).on( "click", function( event ) {
    event.preventDefault();
    window.location.replace = $( this ).attr( "href" );
    return false;
} );

$( "#dobm" ).ddslick( {
    height: "200px",
    width: "100%",
    selectText: "MONTH",
    onSelected: function() {}
} );
$( "#dobd" ).ddslick( {
    height: "200px",
    width: "100%",
    selectText: "DAY",
    onSelected: function() {}
} );
$( "#doby" ).ddslick( {
    height: "200px",
    width: "100%",
    selectText: "YEAR",
    onSelected: function() {}
} );

function shrinkLongUsernames( username ) {
    var bodywidth = $( "body" ).width(),
        unwidth = username.width(),
        unoffset = username.offset(),
        // unfontsize = username.css( "font-size" ),
        unfits = bodywidth - unoffset.left - 5;

    while ( unwidth > unfits ) {
        unwidth = username.width();
        var newfontsize = parseInt( username.css( "font-size" ) ) - 1;
        if ( newfontsize < 12 ) { break; }
        username.css( "font-size", newfontsize + "px" );
    }
}

function openInNewWindow( event ) {
    var thisHref = $( this ).find( "a[target=_self], a[target=_top]" ).attr( "href" );
    event.preventDefault();
    event.stopPropagation();
    window.open( thisHref, "_blank" );
}

function trackMobileAd( type, page, itemCount ) {
    var nonInteraction = 1;
    var trackTitle;
    if ( type === "mobile_ad_show" ) {
        trackTitle = "Mobile ad - track";
        nonInteraction = 1;
    } else if ( type === "taboola_show" ) {
        trackTitle = "Taboola Mobile - show";
        page = $( ".video-title", ".tbfpa" + itemCount.substring( itemCount.length - 1 ) ).text();
        nonInteraction = 1;
    }
    if ( nonInteraction ) {
        gtag('event', trackTitle, {
            'send_to': 'G-2CGLGL25G6',
            'page_name': page,
            'item_count': itemCount,
        });
    } else {
        gtag('event', trackTitle, {
            'send_to': 'G-2CGLGL25G6',
            'page_name': page,
            'item_count': itemCount,
        });
    }
}

var comment_scrollTop;

function show_bezel( notes, autoclose ) {
    $( "body" ).addClass( "modal-open" ).append( "<div id=\"comment_status_bezel_mask\" class=\"mobile_bezel_mask\">&nbsp;</div><div id=\"comment_status_bezel_container\" class=\"mobile_bezel_container\"><div id=\"comment_status_bezel\" class=\"mobile_bezel_content\">" + notes + "</div></div>" );
    $( "#nc", "form#postcomform" ).val( "" );
    if ( autoclose ) {
        setTimeout( function() { close_status_bezel_modal(); }, 2000 );
    }
}

function show_bezel_alert(note, autoClose) {
    var bezel = document.querySelector('.mobile-bezel-alert');
    if (bezel) {
        bezel.querySelector('.mobile-bezel-alert-content').innerHTML = note;
    } else {
        document.body.classList.add('modal-open');
        var bezelMask = document.createElement('div');
        bezelMask.classList.add('mobile-bezel-alert');
        var bezelContent = document.createElement('div');
        bezelContent.classList.add('mobile-bezel-alert-content');
        if (note == 'loading') {
            note = '<img alt="Loading" src="//img.fark.net/images/2011/site/ajax-loader.gif" width="24" height="24">';
        }
        bezelContent.innerHTML = note;
        bezelMask.appendChild(bezelContent);
        document.body.appendChild(bezelMask);
    }
    if ( autoClose ) {
        setTimeout( function() { close_bezel_alert(); }, 2000 );
    }
}

$(document).on("click", ".mobile-bezel-alert", close_bezel_alert);

function close_bezel_alert() {
    if ( typeof CKEDITOR !== "undefined" && !$.isEmptyObject( CKEDITOR.instances ) ) {
        CKEDITOR.instances.wysiwyg_editor.setData( "" );
    } else {
        $( "#wysiwyg_editor", "form#postcomform" ).val( "" );
    }

    document.body.classList.remove('modal-open');
    var bezel = document.querySelector('.mobile-bezel-alert');
    document.body.removeChild(bezel);
}



function resize_modal() {

    // if ($("html").hasClass("ios")) {
    // if ($("#modalIframeObject").length > 0) {
    //     var item = ($("object#modalIframeObject").contents().find("body").find("#postcomform").length > 0) ? $("object#modalIframeObject").contents().find("body").find("#postcomform") : $("object#modalIframeObject").contents().find("body").find("#comment_preview_object");
    //     var height = item.height();
    //     $("object#modalIframeObject").css("height", height);
    // }
}

/* Modal Logic */
// NOTE: as far as I can tell, usercomment is always blank here.
function show_reply_modal( idlink, idcomment ) {
    var link = "/comments/" + idlink + "?mode=new_comment&in_reply_to=" + idcomment;
    var modal_content;

    // we use an object instead of iframe becasue I was having trouble making the iframe scroll properly on ios for some reason.
    // if ( $( "html" ).hasClass( "ios" ) ) {
        // modal_content = "<object id=\"modalIframeObject\" type=\"text/html\" data=\"" + link + "\" style=\"width: 100%; height: 1000px;\"></object>";
    // } else {
        modal_content = "<iframe id=\"modalIframe\" src=\"" + link + "\"></iframe>";
    // }

    // show_mobile_modal("Reply To Comment", modal_content);
    // update_modal_content("Reply To Comment", modal_content, "comment_reply_modal has_iframe mobile_modal_container");
    // update_modal_content("Reply To Comment", modal_content, "comment_reply_modal has_iframe");
    add_mobile_modal( "comment_reply_modal", modal_content, "Reply To Comment", "comment_reply_modal" );
}


function show_preview_modal( data ) {
    var link = "/comments/?" + data;
    var modal_content;
    // if ( Modernizr.ios ) {
        // modal_content = "<object id=\"modalIframeObject\" type=\"text/html\" data=\"" + link + "\" style=\"width: 100%; height: 1000px;\"></object>";
    // } else {
        modal_content = "<iframe id=\"modalIframe\" src=\"" + link + "\"></iframe>";
    // }

    // show_mobile_modal("Preview Comment", modal_content, "comment_prev_modal");
    add_mobile_modal( "comment_preview_modal", modal_content, "Preview Comment", "comment_prev_modal" );
}

function show_conversationview_modal( data, id ) {
    add_mobile_modal( "conversationview_modal", data, "Conversation View", "conv_view_modal" );

    // show_mobile_modal("Conversation View", data, "conv_view_modal");
    var conv_in_array = $.inArray( id, openConversations );
    openConversations.splice( conv_in_array, 1 );
    $( "meta[name=viewport]" ).attr( "content", fark.globals.disableZoomMetaTag );
}

function show_login_modal( id, data ) {
    var modal_content = "<iframe id=\"modalIframe\" src=\"" + data + "\"></iframe>";
    add_mobile_modal( "mobile_login_modal", modal_content, "Login" );

    // show_mobile_modal("Login", modal_content);
}

function add_mobile_modal( id, data, modal_title, extra_class ) {

    $( "body" ).addClass( "modal-open" ).append( "<div id=\"" + id + "_mask\" class=\"mobile_modal_mask\"></div><div id=\"" + id + "\" class=\"mobile_modal_container " + extra_class + "\"><div class=\"mobile_modal_header\"><p class=\"mobile_modal_header_title\">" + modal_title + "</p><img src=\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABB0lEQVRYhdWXsQ2DQAxFjbJFRH+t9X/hSW4ENmMDBsgg6aHOBJeGI1EU0JFAHCy5wuI/bOM7izysUtUGQEeyJ5k29h5Ap6qNiFTybCGEM8nLDqJzfgkhnKcv/7H4BCEilahq4yCeSCZVbWSsuQsAgE64T8OVei+O4olkOhTAjeRQEDeMsdsBALiaWR1jPAFoF+LaGOPJzGoA160AbmZW56ExB5HFc5yZ1SWZKAEYnl/8DuJVPMeUlKy0BG8FALRLzzZtwjmhb8RXAcxBfCO+GmAJ4hPx4wG4lsC1Cb1/Q/dB5D6K3Q+jKRMlKeUex/Ge/hcAvpdS92u5+2Ii3quZiP9yms1lPb8DiVSgRBybzK4AAAAASUVORK5CYII=\" alt=\"Close Modal\" class=\"close_modal_icon\" onclick=\"javascript:close_mobile_modal('" + id + "');\"></div><div class=\"mobile_modal_content\">" + data + "</div></div>" );
    comment_scrollTop = $( document ).scrollTop();
    $( ".mobile_2014_site_outer_container" ).css( "position", "fixed" );
    $( ".mobile_modal_content iframe" ).css( "height", $( ".mobile_modal_content" ).height() );
}

// function show_mobile_modal(header, content, extra_classes) {
//     if (!$(".hidden_modal_container").length) { $("body").append('<div class="hidden_modal_container"></div>'); }
//     $modal_container = $(".hidden_modal_container");
//     if (typeof extra_classes != "undefined") {
//         $modal_container.addClass(extra_classes);
//     }
//     if (header !== null && header !== "" && header !== false) {
//         content = '<div class="mobile_modal_header"><p class="mobile_modal_header_title">'+header+'</p></div>'+content;
//     }
//     content = '<a href="#" rel="modal:close" class="close_modal_icon">X</a>'+content;
//     $modal_container.html(content).modal({showClose: false});
// }

function close_mobile_modal( id ) {
    try {
        Custombox.modal.closeAll();
    }
    catch(err) {}
    $( ".mobile_modal_mask" ).remove();
    $( ".mobile_modal_container" ).remove();

    // if (id == "comment_reply_modal" || id == "comment_preview_modal" || id == "mobile_login_modal") {
    //   $(".mobile_button:disabled").attr("disabled", false);
    //   $(".button_action_working").removeClass("button_action_working");
    //   $(".posting_comment_status").remove();
    // }
    $( ".mobile_button:disabled" ).attr( "disabled", false );
    $( ".button_action_working" ).removeClass( "button_action_working" );
    $( ".posting_comment_status" ).remove();
    $( "body" ).removeClass( "modal-open" );
    $( ".mobile_2014_site_outer_container" ).css( "position", "relative" );
    $( document ).scrollTop( comment_scrollTop );
    $( "meta[name=viewport]" ).attr( "content", fark.globals.zoomMetaTag );
    conversationViewOpen = 0;
    $('.commentPostingActive').removeClass('commentPostingActive');
    return false;
}

function update_modal_title( title ) {
    $( ".mobile_modal_header_title" ).text( title );
}

function close_status_bezel_modal() {
    if ( typeof CKEDITOR !== "undefined" && !$.isEmptyObject( CKEDITOR.instances ) ) {
        CKEDITOR.instances.wysiwyg_editor.setData( "" );
    } else {
        $( "#wysiwyg_editor", "form#postcomform" ).val( "" );
    }
    $( ".mobile_bezel_mask, .mobile_bezel_container" ).remove();
    $( ".comment_post_error" ).remove();
    $( "body" ).removeClass( "modal-open" );
    $( "html" ).css( "position", "static" );
    $( document ).scrollTop( comment_scrollTop );
}

var comment_check_howmany = 0,
    comment_check_interval_started = 0,
    comment_check_interval_started_before = 0;
$( function() {
    /*
        Ajax check for new comments
        Check for new comments every comment_ajax_check_interval (set in comments_page_mobile_2014.tt)
        But only check when the "Load Newer" comments link is visible, otherwise cancel it to save bandwidth

        Use of comment_check_interval_started_before is because we want to check for comments immediately
        the first time it's viewed but then set the interval for any of the next times
    */
    $( "body.mobile_2014_redesign #comments_ajax_load_newer" ).appear();
    $( document.body ).on( "appear", "#comments_ajax_load_newer", function( e, $affected ) {
        if ( comment_check_interval_started != 1 ) {
            if ( comment_check_interval_started_before != 1 ) {
                farkDebug.log( "Running automatic Check_for_new_comments" );
                check_for_new_comments();
            }
            farkDebug.log( "Starting timed new comment count check, interval " + fark.ajax.commentsCheckInterval );
            if ( fark.ajax.commentsCheckInterval > 0 ) {
                comment_check_new_comments_timer = setInterval( function() { check_for_new_comments( 1 ); }, fark.ajax.commentsCheckInterval );
            }
            comment_check_interval_started_before = 1;
        }
        comment_check_interval_started = 1;
    } );
    $( document.body ).on( "disappear", "#comments_ajax_load_newer", function( e, $all_disappeared_elements ) {
        farkDebug.log( "Stopping timed new comment count check" );
        if ( typeof comment_check_new_comments_timer !== "undefined" ) {
            clearInterval( comment_check_new_comments_timer );
        }
        comment_check_interval_started = 0;
    } );
} );

/* mobile version of processSubmitAjax function */
function processSubmitAjax( data ) {

    // Hide submit_errors and submit_notes in case this isn't the first time we're
    //  processing ajax submit on this page
    $( ".submit_errors" ).hide();
    $( ".submit_notes" ).hide();
    if ( typeof data.errors !== "undefined" && data.errors.length ) {
        var submit_errors_list = data.errors.map( function( error ) {
            return "<li>" + error + "</li>";
        } );
        $( ".submit_errors" ).html( submit_errors_list ).show();
    } else if ( typeof data.notes !== "undefined" && data.notes.length ) {
        var submit_notes_list = data.notes.map( function( note ) {
            return "<li>" + note + "</li>";
        } );
        submit_notes_list += "<span style='color: navy; font-weight: bold;'>Link checks out okay.</span>";
        $( ".submit_notes" ).html( submit_notes_list ).show();
    }
    if ( data.source_info && data.source_info.ForceTopic && data.source_info.ForceTopic != 0 && data.source_info.ForceTopic !== null ) {
        $( "#t" ).ddslick( "destroy" );
        $( "select#t" ).hide();
        var forcedTopic = data.source_info.ForceTopic;
        $( "#submit_forced_topic" ).html( "Topic: <img src='" + fark_imgurl + "/2012/topics/" + forcedTopic.toLowerCase() + ".gif' class='retina' alt='" + forcedTopic + "' width='54' height='11' srcset='" + fark_imgurl + "/2012/topics/" + forcedTopic.toLowerCase() + ".gif 1x, " + fark_imgurl + "/2012/topics/" + forcedTopic.toLowerCase() + "-2x.gif 2x'><input type='hidden' name='t' value='" + forcedTopic + "'>" ).show();
    }
    if ( data.source_info && data.source_info.IMG_tagS ) {
        var inputBox = $( "input#srctxt" );
        if ( typeof data.source_info.Image_URI_HiDPI != "undefined" && data.source_info.Image_URI_HiDPI != "" ) {
            var imageHtml = "<img src=\"" + fark_imgurl + data.source_info.Image_URI_LoDPI + "\" class=\"retina\" alt=\"(" + data.source_info.Name + ")\" title=\"" + data.source_info.Name + "\" srcset=\"" + fark_imgurl + data.source_info.Image_URI_LoDPI + " 1x, " + fark_imgurl + data.source_info.Image_URI_HiDPI + " 2x\" width=\"" + data.source_info.Image_URI_LoDPI_Width + "\" height=\"" + data.source_info.Image_URI_LoDPI_Height + "\">";
        } else {
            var imageHtml = data.source_info.IMG_tagS;
        }
        inputBox.hide();
        $( "#submit_source_image" ).html( "Source: " + imageHtml ).retina().show();

    } else {
        if ( !data.errors[ 0 ] ) {
            $( "input#srctxt" ).val( "Some Guy" );
        }
    }
    $( ".checkingLinkDiv" ).remove();
    checking_submit_link = 0;

    /* Run retina script to get the retina version of source tag */
    $( "img.retina" ).retina();
}

$( document ).on( "change", "body.mobile_2014_redesign input#include_orig_comment", function() {
    if ( this.checked ) {
        $( "textarea#qt" ).show();
        $( "#include_qt_hidden" ).remove();
    } else {
        $( "textarea#qt" ).hide();
        $( this ).after( "<input type=\"hidden\" name=\"include_qt\" value=\"0\" id=\"include_qt_hidden\">" );
    }
} );

var has_sized_quote_once = false;
$( document ).on( "focus", "body.mobile_2014_redesign textarea#qt", function() {
    var thisHeight = $( this ).height();
    if ( !has_sized_quote_once ) {
        if ( this.scrollHeight > thisHeight ) {
            $( this ).height( 0 ).height( this.scrollHeight );
        }
        has_sized_quote_once = true;
        parent.resize_modal( $( "#postcomform" ).height() );
    }
    if ( $( "html" ).hasClass( "android" ) ) {
        postform_on_focus();
    }
} );
$( document ).on( "blur", "body.mobile_2014_redesign textarea#qt", function() {
    if ( $( "html" ).hasClass( "android" ) ) {
        postform_on_blur();
    }
} );

var is_checking_for_new_comment = false;
function check_for_new_comments( viatimer ) {
    if (fark.globals.ufcheck && !fark.page.isAllHouseAds) {
        return false;
    }
    if ( comment_check_howmany >= 30 ) { // with a 30 second timer, this is 15 minutes after page load.
        farkDebug.log( "too many new comment count requests, stopping interval timer" );
        if ( typeof comment_check_new_comments_timer !== "undefined" ) {
            fark.globals.newCommentIntervalChecksDisabled = true;
            clearInterval( comment_check_new_comments_timer );
        }
        return;
    }
    if ( allow_interval_check( "comments" ) && !is_checking_for_new_comment ) {
        is_checking_for_new_comment = true;
        var load_button = $( "#comments_ajax_load_newer" );
        fark.comments.ajaxIDLink = ( typeof fark.comments.ajaxIDLink === "undefined" ) ? parent.fark.comments.ajaxIDLink : fark.comments.ajaxIDLink;
        var ajax_url = fark.globals.commentAjaxFetchUrl;
        var ajax_data = "IDLink=" + fark.comments.ajaxIDLink + "&IDComment=" + fark.comments.newestLoadedComment + "&is_count_only=1&subset=newer";
        if ( typeof viatimer !== "undefined" ) {
            ajax_data = ajax_data + "&timercounter=" + comment_check_howmany;
        } else {
            ajax_data = ajax_data + "&timercounter=none";
        }
        farkDebug.log( "Checking for new comment count (times so far: " + comment_check_howmany + ") viatimer=" + viatimer );
        comment_check_howmany++;
        $.ajax( {
            type: "GET",
            url: ajax_url,
            data: ajax_data,
            timeout: 10000,
            success: function( data ) {

                // farkDebug.log("There are "+data+" newer comments left to load.");
                fark.comments.commentCountNewer = data;
                var load_button_text = return_ajax_link_text( "comments", "newer" );
                if ( load_button_text ) {
                    load_button.text( load_button_text );
                } else {
                    $( "li.load_newer_container" ).remove();
                }
            },
            complete: function( data ) {
                is_checking_for_new_comment = false;
            }
        } );
    }
}

function check_new_comment_posting_position( lastID ) {
    var load_more_button = $( "#comments_ajax_load_newer" );
    fark.comments.ajaxIDLink = ( typeof fark.comments.ajaxIDLink === "undefined" ) ? parent.fark.comments.ajaxIDLink : fark.comments.ajaxIDLink;
    var ajax_url = fark.globals.commentAjaxFetchUrl;
    var ajax_data = "IDLink=" + fark.comments.ajaxIDLink + "&IDComment=" + fark.comments.newestLoadedComment + "&is_count_only=1&subset=newer";
    $.ajax( {
        type: "GET",
        url: ajax_url,
        data: ajax_data,
        success: function( data ) {
            var other_new_comments = data;
            fark.comments.commentCountNewer = data;
            if ( other_new_comments > 0 && other_new_comments <= fark.globals.numPrevCommentsToShowBeforePost ) {
                var load_more_button = $( "#comments_ajax_load_newer" );
                var ajax_url = fark.globals.commentAjaxFetchUrl;
                var ajax_data = "IDLink=" + fark.comments.ajaxIDLink + "&IDComment=" + fark.comments.newestLoadedComment + "&subset=newer&limit=" + fark.globals.numPrevCommentsToShowBeforePost;
                get_and_append_ajax( "comments", "newer", load_more_button, ajax_url, ajax_data, $( "ul#comments_container li.load_newer_container" ) );
            } else if ( other_new_comments > fark.globals.numPrevCommentsToShowBeforePost ) {
                $.get( "/ajax/comments/fetch?IDLink=" + fark.comments.ajaxIDLink + "&IDComment=" + lastID, function( data ) {
                    $( "ul#comments_container li.new_comment_preview" ).html( data.html );
                    current_preview_comment_id = lastID;
                } );
                var comments_to_check = ( fark.comments.commentCountNewer > fark.ajax.commentsPerCall ) ? fark.ajax.commentsPerCall : fark.comments.commentCountNewer;
                $( "#comments_ajax_load_newer" ).text( "Load " + comments_to_check + " of " + fark.comments.commentCountNewer + " newer comments" );
            }
        }
    } );
}

/* Load older comments */
$( document ).on( "click", "#comments_ajax_load_older", load_older_comments );

function load_older_comments() {
    // farkDebug.log("Clicked to load older comments");
    if ( !fark.ajax.processingOlderComments ) {
        fark.ajax.processingOlderComments = true;
        var load_button = $("a#comments_ajax_load_older");
        var ajax_url = fark.globals.commentAjaxFetchUrl;
        var ajax_data = "IDLink=" + fark.comments.ajaxIDLink + "&IDComment=" + fark.comments.oldestLoadedComment + "&subset=older&limit=" + fark.ajax.commentsPerCall;
        get_and_append_ajax( "comments", "older", load_button, ajax_url, ajax_data, $( "ul#comments_container" ) );
    } else {
        console.log( "Stop clicking so quick." );
    }
    return false;
};

/* Load newer comments */
$( document ).on( "click", "#comments_ajax_load_newer", function() {

    // farkDebug.log("Clicked to load newer comments");
    if ( !fark.ajax.processingNewerComments ) {
        fark.ajax.processingNewerComments = true;
        var load_button = $( this );
        var ajax_url = fark.globals.commentAjaxFetchUrl;
        var ajax_data = "IDLink=" + fark.comments.ajaxIDLink + "&IDComment=" + fark.comments.newestLoadedComment + "&subset=newer&limit=" + fark.ajax.commentsPerCall;
        get_and_append_ajax( "comments", "newer", load_button, ajax_url, ajax_data, $( "ul#comments_container li.load_newer_container" ) );
        comment_check_howmany = 0;  // reset counter because we know they're still there, since they manually clicked something
    } else {
        console.log( "Stop clicking so quick." );
    }
    return false;
} );

// fark.voting.openVoteStatusBoxes = [];
// fark.voting.currentlyVotingIds = [];
// function fark_cmtvote_mobile( me, idcomment, votetype, votetypenum ) {
//     var thisCommentInfo = idcomment + "-" + votetype;
//     if ( $.inArray( thisCommentInfo, fark.voting.currentlyVotingIds ) < 0 ) {
//         fark.voting.currentlyVotingIds.push( thisCommentInfo );
//         hasvoted++;
//         var ischecked = null;
//         var waschecked = null;
//         var currentCount = $( "span.vote_" + votetype + idcomment + "_count" );
//         if ( $( me ).hasClass( "voted_" + votetype ) ) {
//             waschecked = 1;
//             ischecked = 0;
//         } else {
//             waschecked = 0;
//             ischecked = 1;
//         }
//         var formvars_map = { IDLink: fark.comments.ajaxIDLink, tok: fark.ajax.globalVoteToken, IDComment: idcomment, action: "vote" + votetypenum, actionval: ischecked };

//         var vote_count_container = $( this ).next(),
//             vote_count_text = vote_count_container.text(),
//             vote_status_text = "",
//             vote_status_type = "";
//         switch ( votetype ) {
//         case "Smartest":
//             vote_status_type = "status_box_smartest";
//             break;
//         case "Funniest":
//             vote_status_type = "status_box_funniest";
//             break;
//         default:
//             vote_status_type = "status_box_vote";
//         }

//         $.get( "/ajax/comments-action", formvars_map,
//             function( data ) {

//                 // other votetypes need ir to hide the image, but android whines about how I tried to unset the text-indent
//                 extraClasses = ( votetype != "Votes" ) ? " ir" : "";
//                 if ( ischecked ) {
//                     $( me ).removeClass().addClass( "voted_" + votetype + " mobile_vote_button" + extraClasses );
//                     $( currentCount ).text( parseInt( $( currentCount ).text() ) + 1 );
//                     vote_status_text = ( votetype == "Votes" ) ? "Voted" : votetype + ": Voted";
//                 } else {
//                     $( me ).removeClass().addClass( "vote_" + votetype + " mobile_vote_button" + extraClasses );
//                     $( currentCount ).text( parseInt( $( currentCount ).text() ) - 1 );
//                     vote_status_text = ( votetype == "Votes" ) ? "Vote removed" : votetype + ": Vote removed";
//                 }
//                 $( me ).parent().append( "<div class='vote_status_box vote_status_box" + idcomment + votetype + " " + vote_status_type + "'>" + vote_status_text + "</div>" );
//                 fark.voting.openVoteStatusBoxes.push( idcomment + votetype );
//                 setTimeout( function() {
//                     if ( fark.voting.openVoteStatusBoxes.length ) {
//                         var this_idcomment = fark.voting.openVoteStatusBoxes.shift();
//                         $( ".vote_status_box" + this_idcomment ).fadeOut( "500", function() { $( this ).remove(); } );
//                     } else {
//                         $( ".vote_status_box" ).fadeOut( "500", function() { $( this ).remove(); } );
//                     }
//                 }, 1000 );
//                 fark.voting.currentlyVotingIds.splice( $.inArray( thisCommentInfo, fark.voting.currentlyVotingIds ), 1 );
//             } )
//             .fail( function( data, txtstat, errtxt ) {
//                 if ( waschecked ) {
//                     $( this ).removeClass().addClass( "voted" + votetype + " mobile_vote_button ir" );
//                 } else {
//                     $( this ).removeClass().addClass( "voting" + votetype + "Button mobile_vote_button ir" );
//                 }
//                 fark.voting.currentlyVotingIds.splice( $.inArray( thisCommentInfo, fark.voting.currentlyVotingIds ), 1 );
//             }
//             );
//         return false;
//     } else {
//         return false;
//     }
// }

/* When menu bar icon is clicked, slide out */
$( document ).on( "click", "#navbar_icon", function() {
    document.getElementById("nav_menu").classList.toggle("open");
    document.body.classList.toggle("menuOpen");
    var targetElement = document.querySelector("#nav_menu");
    if ($("body").hasClass("menuOpen")) {
        bodyScrollLock.disableBodyScroll(targetElement);
    } else {
        bodyScrollLock.enableBodyScroll(targetElement);
    }

    return false;
} );

$( document ).on( "click", "#nav_menu_mask", function() {
    document.getElementById("nav_menu").classList.remove("open");
    document.body.classList.remove("menuOpen");
    var targetElement = document.querySelector("#nav_menu");
    bodyScrollLock.enableBodyScroll(targetElement);

    return false;
});

$( document ).on( "click", "body.mobile_2014_redesign .reply_to_comment", function() {
    var idlink = fark.page.headline_linkid,
        idcomment = $( this ).attr( "data-cid" ),
        usercomment = "";
    show_reply_modal( idlink, idcomment );
    return false;
} );

$( document ).on( "submit", "body.mobile_2014_redesign form#linksubmitform", function( event ) {
    $( ".mobile_button" ).addClass( "fake_disabled" );
} );

/* Submitting from post_form_new modal */
$( document ).on( "submit", "body.mobile_2014_redesign form#postcomform", function( event ) {
    event.preventDefault();
    comment_scrollTop = $( document ).scrollTop();
    $( ".mobile_button", this ).prop( "disabled", true );
    $( ".comment_post_error" ).remove();
    var isInIframe = ( self != top ) ? true : false;
    var thisForm = $( this );
    var thisSubmittedForm = this;

    // mark processingnewercomments to true so "load newer comments" doesn't work, and change text
    fark.ajax.processingNewerComments = true;
    $("#comments_ajax_load_newer").text("Adding Comment...");


    var formValid = $.Deferred();
    if (this.usingSwearJar.value == "true") {
        // using swearjar button, check their availability.
        formValid = checkSwearJar();
    } else {
        formValid.resolve(true);
    }

    formValid.done(function(val) {
        if (val == false) {

            // swearjar failed, so reset things.
            // document.querySelector('input[name="usingSwearJar"]').value = "false";
            thisSubmittedForm.usingSwearJar.value = "false";
            $( ".mobile_button", thisForm ).prop( "disabled", false );
            $( ".mobile_button", thisForm ).removeClass("button_action_working");
            return;
        }

        if ( !fark.globals.useAjaxToPost && !isInIframe ) {
            event.preventDefault();
                thisForm.append( "<input type=\"hidden\" name=\"is_using_js\" value=\"1\">");

	            // Get comment text
	            var nc_comment_text = CKEDITOR.instances.wysiwyg_editor.getData() ;
                qt_comment_text = thisForm.find( "#qt" ).val(),
                nc_cache_key = fark_ajcache( nc_comment_text ),
                qt_cache_key = fark_ajcache( qt_comment_text );

            // when both promises are resolved, do the function
            $.when( nc_cache_key, qt_cache_key ).done( function( nc_key, qt_key ) {
                var data;

                // fark_ajcache will still resolve a promise with the data "fail"
                // so if either neither of them fail, great, go ahead and do this logic.
                if ( nc_key != "fail" && qt_key != "fail" ) {

                    // append the keys to the form
                    thisForm.find( "input[name='nc_cache'], input[name='qt_cache']" ).remove();
                    thisForm.append( "<input type=\"hidden\" name=\"nc_cache\" value=\"" + nc_key + "\"><input type=\"hidden\" name=\"qt_cache\" value=\"" + qt_key + "\">" );

                    // whenever I tried to remove the inputs qt/nc from the form and serialize
                    // I think because of how ckeditor handles updating it wasn't working
                    // correctly, so I just use regex and remove them from the serialized string
                    data = thisForm.serialize();
                    data = data.replace( /&(?:qt|nc)+?=[^&]*/ig, "" );
                } else {

                    // something failed, so just run the same old normal way.
                    data = thisForm.serialize();
                }

                // this will run whether there is a FAIL or not, if there is a fail
                // I just submit it the way we used to with nc and qt
                // get the form data we'll be using
                show_preview_modal( data );
                // resetting check for new comments link to work again since the comment was posted.
                // though i think in this case the modal will be covering it anyway
                fark.ajax.processingNewerComments = false;
                $("#comments_ajax_load_newer").text("Check for new comments");
                return false;

            } );
        } else {
            if ( fark.globals.useAjaxToPost ) {
                event.preventDefault();
                var data = thisForm.serialize();
                form_data = data.replace( /&(?:qt_cache|nc_cache)+?=[^&]*/ig, "" );
                $.ajax( {
                    type: "POST",
                    url: thisForm.attr( "action" ),
                    data: form_data,
                    dataType: "json",
                    success: function( data ) {
                        if ( data.success == "1" ) {
                            parent.check_new_comment_posting_position( data.comment_id );
                        } else {
                            $( "textarea#nc" ).before( "<span class=\"comment_post_error\">" + data.notes + "</span>" );
                        }
                        if ( data.login_continue_url ) {
                            show_login_modal( "comment_login_modal", data.login_continue_url );
                            inIframe = 1;

                            //open iframe with login and ]/login?continue="+data.login_continue_url+ login_continue_url %]
                        } else {
                            if ( data.close_modal == 1 ) {
                                if ( data.success ) {
                                    parent.show_bezel_alert( data.notes, true );
                                } else {
                                    parent.show_bezel_alert( data.notes, true );
                                }
                                parent.close_mobile_modal( "comment_reply_modal" );
                                Custombox.modal.closeAll();
                            } else {
                                resetCommentPostingButtons();
                                $( ".posting_comment_status" ).remove();

                                // show error notes
                                // enable button again
                            }
                        }
                    },
                    complete: function( data ) {
                        resetCommentPostingButtons();
                        // resetting check for new comments link to work again since the comment was posted.
                        // and change text back to check for new comments
                        $("#comments_ajax_load_newer").text("Check for new comments");
                        fark.ajax.processingNewerComments = false;
                    }
                } );
                return false;
            } else {

                document.getElementById("postcomform").submit();
            }
        }
    });
});

/* Submitting from preview made modal */
$( document ).on( "submit", "body.mobile_2014_redesign form#previewpostcomform", function( event ) {
    comment_scrollTop = $( document ).scrollTop();
    $( ".mobile_button", this ).prop( "disabled", true );
    $( ".comment_post_error" ).remove();
    var isInIframe = ( self != top ) ? true : false;
    var thisForm = $( this );
    var thisSubmittedForm = this;

    // mark processingnewercomments to true so "load newer comments" doesn't work, and change text
    fark.ajax.processingNewerComments = true;
    $("#comments_ajax_load_newer").text("Adding Comment...");

    var formValid = $.Deferred();
    if (this.usingSwearJar.value == "true") {
        // using swearjar button, check their availability.
        formValid = checkSwearJar();
        event.preventDefault();

    } else {
        formValid.resolve(true);
    }

    formValid.done(function(val) {
        if (val == false) {

            // swearjar failed, so reset things.
            // document.querySelector('input[name="usingSwearJar"]').value = "false";
            thisSubmittedForm.usingSwearJar.value = "false";
            $( ".mobile_button", thisForm ).prop( "disabled", false );
            $( ".mobile_button", thisForm ).removeClass("button_action_working");
            return;
        }

        if ( fark.globals.useAjaxToPost == "1" ) {
            var thisForm = $( "#previewpostcomform" );
            event.preventDefault();

            $.ajax( {
                type: "POST",
                url: thisForm.attr( "action" ),
                data: thisForm.serialize(),
                success: function( data ) {
                    if ( typeof CKEDITOR.instances.wysiwyg_editor !== "undefined" ) {
                        CKEDITOR.instances.wysiwyg_editor.setData( "" );
                    }
                    if ( data.success == "1" ) {
                        parent.check_new_comment_posting_position( data.comment_id );
                    } else {
                        $( ".comments_content" ).before( "<span class=\"comment_post_error\" style=\"margin-left: 10px\">" + data.notes + "</span>" );
                    }
                    if ( data.close_modal == 1 ) {
                        if ( data.success ) {
                            parent.show_bezel_alert( data.notes, true );
                        } else {
                            parent.show_bezel_alert( data.notes, true );
                        }
                        parent.resetCommentPostingButtons();
                        parent.close_mobile_modal( "comment_reply_modal" );
                    } else {
                        parent.resetCommentPostingButtons();
                        $( ".posting_comment_status" ).remove();
                    }
                },
                dataType: "json"
            } );
        }
    })
} );

function resetCommentPostingButtons() {
    $( ".mobile_button" ).attr( "disabled", false );
    $( ".button_action_working" ).removeClass( "button_action_working" );
    $(".swearJarButton.theySwore").removeClass("showSJButton");
    $("#postcomform").removeClass("commentPostingActive");
}

function set_detailed_view(forceAll) {
    $("li[data-headline-ed]").addClass("detailedView");
    $(".active_display_icon").removeClass("active_display_icon");
    $(".detail_display").addClass("active_display_icon");
    if (typeof forceAll !== undefined && forceAll) { $(".headline_details").show(); }

}
function set_list_view() {
    $("li[data-headline-ed]").removeClass("detailedView");
    $(".active_display_icon").removeClass("active_display_icon");
    $(".list_display").addClass("active_display_icon");
}

$( document ).on( "click", "body.mobile_2014_redesign .mobile_bezel_mask, body.mobile_2014_redesign .mobile_bezel_container", close_status_bezel_modal );
$( document ).on( "click", ".detail_display", function() { 
    if (fark.user.hl_view_mode_mobile != 'd') {
        fark.helpers.setCookie('hlview', 'd', 365);
        fark.user.hl_view_mode_mobile = 'd';
        gtag('event', 'Mobile HL change view', {
            'send_to': 'G-2CGLGL25G6',
            'page_name': fark.page.trackingPage,
            'event_label': 'd',
        });
    }
    set_detailed_view(true);

});
$( document ).on( "click", ".list_display", function() { 
    if (fark.user.hl_view_mode_mobile != 'l') {
        fark.helpers.setCookie('hlview', 'l', 365);
        fark.user.hl_view_mode_mobile = 'l';
        gtag('event', 'Mobile HL change view', {
            'send_to': 'G-2CGLGL25G6',
            'page_name': fark.page.trackingPage,
            'event_label': 'l',
        });
    }
    set_list_view();
});


$( document ).on( "click", ".showHide", function() {
    var parent = $(this).parents("li");
    if (parent.hasClass('detailedView')) {
        parent.find(".closeShareComment:visible").trigger("click");
        parent.find(".headline_details").slideUp(500, function() {
            parent.toggleClass('detailedView');
            gtag('event', 'Mobile HL expand/collapse', {
                'send_to': 'G-2CGLGL25G6',
                'page_name': fark.page.trackingPage,
                'event_label': 'collapse'
            });
        });
    } else {
        parent.toggleClass('detailedView');
        parent.find(".headline_details").slideDown(500);
        gtag('event', 'Mobile HL expand/collapse', {
            'send_to': 'G-2CGLGL25G6',
            'page_name': fark.page.trackingPage,
            'event_label': 'expand'
        });
    }
    var scrollOffset = ($("body").hasClass('sticky_mobile_header')) ? 52 : 4;
    $('html, body').animate({ scrollTop: (parent.offset().top - scrollOffset) }, 500);


    var youtubeContainer = $(this).parents("li").find(".youtubeContainer");
    if (youtubeContainer.length) {
        var vidId = youtubeContainer.find(".youtube").data('embed');
        if (yt_players[vidId]) {
            yt_players[vidId].stopVideo();
        }
    }
    return false;
});

// making sure taboola links open in a new window
$( document ).on( "click", "#headline_container .tbfpa", function( event ) { openInNewWindow( event ); } );

$( document ).ready( function() {
    // set height of sidebar menu so it's always full page height
    // we had a problem with shorter sites
    $( "#container" ).css( "min-height", $( "#nav_menu ul" ).height() + 50 );

    $( ".long_user_name" ).each( function() { shrinkLongUsernames( $( this ).parent() ); } );

    // $(".voteUp, .voteDown").on("click", function() {
    //     console.log("headline_linkid: "+fark.page.headline_linkid);
    //     console.log("encrypted_userId: "+fark.user.encr);
    //     console.log("you clicked to "+$(this).attr("class"));
    //     $(this).toggleClass("voted");
    //     return false;
    // });

} );

 

// function selectembedtextarea() {
//     var $this = $("#embed-preview-code");
//     $this.select();

//     window.setTimeout(function() {
//         $this.select();
//     }, 100);

//     // Work around WebKit's little problem
//     function mouseUpHandler() {
//         // Prevent further mouseup intervention
//         $this.off("mouseup", mouseUpHandler);
//         return false;
//     }

//     $this.mouseup(mouseUpHandler);
// }

function create_infeed_adunit_and_fetch (full_dfp_ad_unit_name, defined_ad_slot, ad_div_id, ad_sizes, run_prebid, sharethrough_id, sovrn_tagid) {

    var filtered_ad_sizes = ad_sizes.filter(size => {return size != 'fluid'});

    var bid_array = [
        {
            "bidder": "sharethrough",
            "params": {
                "pkey": sharethrough_id
            }
        }
    ];
    if (run_prebid) {
        bid_array.push(
                {
                    "bidder": "rubicon",
                    "params": {
                        "accountId": "9659",
                        "siteId": "17567",
                        "zoneId": "1730466", 
                        "position": "btf",
                    }
                }, {
                    "bidder": "shemedia",
                    "params": {
                        "accountId": "10278",
                        "siteId": "210096",
                        "zoneId": "3421080",
                        "position": "btf",
                    }
                }, {
                    "bidder": "openx",
                    "params": {
                        "delDomain": "fark-d.openx.net",
                        "unit": "541116787",
                    }
                // }, {
                //     "bidder": "emx_digital",
                //     "params": {
                //         "tagid": '105884',
                //     }
                }, {
                    "bidder": "appnexus",
                    "params": {
                        "placementId": "19435892", 
                    }
                }, {
                    "bidder": "sonobi",
                    "params": {
                        "ad_unit": full_dfp_ad_unit_name,
                        "dom_id": ad_div_id
                    }
                }, {
                    "bidder": "33across",
                    "params": {
                        "siteId": 'aIhRXGQX8r6OkEaKkGJozW',
                        "productId": 'siab'
                    }
                }, {
                    "bidder": 'pubmatic',
                    "params": {
                        "publisherId": '161641',
                        "adSlot": '4350365'
                    }
                }, {
                    "bidder": 'smartadserver',
                    "params": {
                        "siteId": '561690',
                        "pageId": '1705789',
                        "formatId": '118129'
                    }
                }, {
                    "bidder": 'unruly',
                    "params": {
                        "siteId": '259336'
                    }
                },  {
                    "bidder": "datablocks",
                    "params": {
                        "source_id": 2727819
                    }
                }
        );
        if (sovrn_tagid) {
            bid_array.push(
                {
                    "bidder": "sovrn",
                    "params": {
                        "tagid": sovrn_tagid
                    }
                }
            );
        }
    }

    pbjs.que.push(function() {
        pbjs.addAdUnits({
            "code": ad_div_id,
            mediaTypes: {
                banner: {
                    sizes: filtered_ad_sizes
                }
            },
            "bids": bid_array
        });

        /* Do not request bids... do amazon and prebid at the same time in executeParallelAuctionAlongsidePrebidForSingleAdUnit
        pbjs.requestBids({
            timeout: PREBID_TIMEOUT,
            adUnitCodes: [ad_div_id],
            bidsBackHandler: function() {
                pbjs.setTargetingForGPTAsync([ad_div_id]);
                googletag.pubads().refresh([defined_ad_slot]);
            }
        });
        */

    });

    // This function is defined in farktools_common
    // executeParallelAuctionAlongsidePrebidForSingleAdUnit(ad_div_id, full_dfp_ad_unit_name, defined_ad_slot, filtered_ad_sizes);

}

function is_mobile_data () {
    try {
        // Check the Session Storage - which clears when the page is closed 
        deviceIsMobileData = window.sessionStorage.getItem('deviceIsMobileData');
        
        // Use stored value 
        if (deviceIsMobileData != null) {
            console.error('Mobile Data (saved): ' + deviceIsMobileData);

        // Lookup the value 
        } else {

            let request = new XMLHttpRequest();
            request.onload = function(e) {
                if (e.target.readyState === XMLHttpRequest.DONE && e.target.status === 200) {
                
                    // convert JSON to reply 
                    let data = JSON.parse( request.responseText );
                    
                    // Save as Int so it stores correctly 
                    deviceIsMobileData = (data.mobile) ? 1 : 0;

                    // Pass to googletag
                    console.error('Mobile Data (query): ' + deviceIsMobileData);
                            
                    // Save to session for next page load 
                    window.sessionStorage.setItem('deviceIsMobileData',deviceIsMobileData);
                }
            }
            request.onerror = function(e) { console.error('Location: API query failed', e); }
            request.ontimeout = function(e) { console.error('Location: API query timed out'); }
            request.timeout = 1000; // Set timeout to 1 second (1000 milliseconds)
            request.open('GET', 'https://pro.ip-api.com/json/?key=ZxSSLwZtxrKxQbv&fields=status,countryCode,mobile');
            request.send();
        }   
        
    } catch(error){
        console.error('Location: API query failed: ' + error);
    }

    return deviceIsMobileData;

}


